import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"

const ImpressumPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <HeroImage />
    <div className="container mt-3">
      <h1>Impressum</h1>
      <p>
        <span style={{ color: "#f07e14" }}>
          <b>Angaben gemäß § 5 TMG:</b>
        </span>
      </p>
      <p>
        <span>Helmut Kraft, Dipl.-Ing. (FH)</span>
        <br />
        <span>Schulungszentrum für Spritzgießtechnik</span>
        <br />
        <span>Bergmattinger Weg 12</span>
        <br />
        <span>D-93161 Sinzing (Regensburg)</span>
        <br />
        <span>GERMANY</span>
      </p>
      <p>
        <span style={{ color: "#f07e14" }}>
          <b>Kontakt:</b>
        </span>
      </p>
      <p>
        <span>
          Telefon:
          <br />
          0049-941-30766- 10
        </span>
        <br />
        <span>
          E-Mail:
          <br />
          h.kraft@spritzgiessfuehrerschein.de
        </span>
      </p>
      <p>
        <span style={{ color: "#f07e14" }}>
          <b>Geschäftsführung:</b>
        </span>
      </p>
      <p>
        <span>Dipl. Ing.(FH) Helmut Kraft</span>
      </p>
      <p>
        <span style={{ color: "#f07e14" }}>
          <b>Umsatzsteuer-ID:</b>
        </span>
      </p>
      <p>
        <span>
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
        </span>
        <br />
        <span>DE157417928</span>
      </p>
      <p>
        <span style={{ color: "#f07e14" }}>
          <b>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</b>
        </span>
      </p>
      <p>
        <span>Dipl.-Ing. (FH) Helmut Kraft,</span>
        <br />
        <span>Bergmattinger Weg 12</span>
        <br />
        <span>D-93161 Sinzing (Regensburg)</span>
      </p>
      <p>
        <span style={{ color: "#f07e14" }}>
          <b>Quellenangaben für die verwendeten Bilder und Grafiken:</b>
        </span>
      </p>
      <p>
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – Pixelot
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – Gerhard Seybert
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – Fabulous
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – digitalstock
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – Günter Menzl
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – Kzenon
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – aldorado
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – ktsdesign
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – arsdigital
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – Frofoto
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – Nitr
        </span>
        <br />
        <span>
          <span style={{ color: "#f07e14" }}>
            <a
              style={{ color: "#f07e14" }}
              href="http://de.fotolia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              de.fotolia.com
            </a>
          </span>{" "}
          – Kaarsten
        </span>
      </p>
      <h2>
        <span style={{ color: "#f07e14" }}>
          <b>Haftungs­ausschluss (Disclaimer)</b>
        </span>
      </h2>
      <p>
        <span>
          <b>Haftung für Inhalte</b>
        </span>
      </p>
      <p>
        <span>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet,
          übermittelte oder gespeicherte fremde Informationen zu überwachen oder
          nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung
          von Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </span>
      </p>
      <p>
        <span>
          <b>Haftung für Links</b>
        </span>
      </p>
      <p>
        <span>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </span>
      </p>
      <p>
        <span>
          <b>Urheberrecht</b>
        </span>
      </p>
      <p>
        <span>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </span>
      </p>
    </div>
  </Layout>
)

export default ImpressumPage
